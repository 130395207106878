import { useEffect, useMemo, useState } from "react";
import { capitalize } from "lodash";
import {
  Ac1CredentialSetupCategory,
  useAc1AvailableQrCodeSetupsQuery,
  useAc1AvailableVirtualCredentialsProvidersLazyQuery,
  useAc1BuildingsQuery,
  useAc1IntegrationsQuery,
  useAc1ProvidersQuery,
} from "../../../../../generated/admin";
import { OptionType } from "../../../../../model/OptionType";

export type Building = {
  visitorsEnabled: boolean;
  employeesEnabled: boolean;
  building: OptionType;
};

export type Integration = Omit<
  NonNullable<
    ReturnType<typeof useAc1IntegrationsQuery>["data"]
  >["ac1Integrations"][number],
  "uuid" | "provider"
> & {
  uuid: string;
  provider: string;
  buildings: Building[];
  employeePhysicalCardEnabled: boolean;
  employeeVirtualCredentialSetupUuid?: string;
  employeeQrCodeCredentialSetupUuid?: string;
  visitorPhysicalCardEnabled: boolean;
  visitorQrCodeCredentialSetupUuid?: string;
};

export function useAc1Data(siteUuid: string) {
  const [virtualCredentialOptionsMap, setVirtualCredentialOptionsMap] =
    useState<Map<string, OptionType[]>>(new Map());
  const { data: providersData } = useAc1ProvidersQuery();
  const providerOptions: OptionType[] = providersData?.ac1Providers ?? [];

  const { data: siteBuildingsData, loading: siteBuildingsLoading } =
    useAc1BuildingsQuery({
      variables: { destinationUuid: siteUuid },
    });
  const siteBuildingOptions: OptionType[] =
    siteBuildingsData?.buildings.edges.flatMap(({ node }) =>
      node == null ? [] : [{ value: node.uuid, label: node.name }]
    ) ?? [];

  const { data: availableQrCodeSetupsData } = useAc1AvailableQrCodeSetupsQuery({
    variables: { siteUuid },
  });
  const qrCodeOptions: OptionType[] =
    availableQrCodeSetupsData?.ac1CredentialSetups.flatMap(
      ({ uuid, passName, generator }) => {
        if (uuid == null || generator == null) {
          return [];
        }

        return {
          value: uuid,
          label: `${passName} - ${capitalize(
            generator.replaceAll(/[_-]+/g, " ")
          )}`,
        };
      }
    ) ?? [];

  const { data: integrationsData, loading: integrationsLoading } =
    useAc1IntegrationsQuery({
      variables: { siteUuid },
    });
  const integrations: Integration[] = useMemo(
    () =>
      siteBuildingsLoading || integrationsLoading
        ? []
        : integrationsData?.ac1Integrations.map((integration) => ({
            ...integration,
            provider: integration.provider.label,
            accessPassName: integration.accessPassName ?? "",
            config:
              integration.config == null
                ? null
                : typeof integration.config === "string"
                ? integration.config
                : JSON.stringify(integration.config, null, 2),
            buildings: integration.buildingIntegrations.map(
              ({ visitorsEnabled, employeesEnabled, building }) => {
                return {
                  visitorsEnabled,
                  employeesEnabled,
                  building: {
                    value: building.uuid,
                    label: building.name,
                  },
                };
              }
            ),
            employeePhysicalCardEnabled:
              integration.employeeCredentialSetups?.some(
                (cred) => cred.category === Ac1CredentialSetupCategory.Physical
              ) ?? false,
            employeeVirtualCredentialSetupUuid:
              integration.employeeCredentialSetups?.find(
                (cred) => cred.category === Ac1CredentialSetupCategory.Virtual
              )?.uuid ?? undefined,
            employeeQrCodeCredentialSetupUuid:
              integration.employeeCredentialSetups?.find(
                (cred) => cred.category === Ac1CredentialSetupCategory.QrCode
              )?.uuid ?? undefined,
            visitorPhysicalCardEnabled:
              integration.visitorCredentialSetups?.some(
                (cred) => cred.category === Ac1CredentialSetupCategory.Physical
              ) ?? false,
            visitorQrCodeCredentialSetupUuid:
              integration.visitorCredentialSetups?.find(
                (cred) => cred.category === Ac1CredentialSetupCategory.QrCode
              )?.uuid ?? undefined,
          })) ?? [],
    [
      integrationsData?.ac1Integrations,
      integrationsLoading,
      siteBuildingsLoading,
    ]
  );

  const [loadVirtualCredentials] =
    useAc1AvailableVirtualCredentialsProvidersLazyQuery();

  useEffect(() => {
    if (
      siteBuildingsLoading ||
      integrationsLoading ||
      integrationsData?.ac1Integrations == null
    ) {
      return;
    }

    (async () => {
      const virtualCredentialOptions = await Promise.all(
        integrationsData?.ac1Integrations.map<Promise<[string, OptionType[]]>>(
          async ({ uuid, provider }) => {
            const credentials = await loadVirtualCredentials({
              variables: { siteUuid, provider: provider.value },
            });
            return [
              uuid as string,
              credentials.data?.ac1AvailableVirtualCredentialsProviders.map(
                ({ passName, virtualCredentialsProvider, ...cred }) => {
                  return {
                    label:
                      passName +
                      (virtualCredentialsProvider?.name != null
                        ? ` - ${virtualCredentialsProvider.name.trim()}`
                        : ""),
                    value: cred.uuid as string,
                  };
                }
              ) ?? [],
            ];
          }
        ) ?? []
      );

      setVirtualCredentialOptionsMap(new Map(virtualCredentialOptions));
    })();
  }, [
    integrationsData?.ac1Integrations,
    integrationsLoading,
    loadVirtualCredentials,
    siteBuildingsLoading,
    siteUuid,
  ]);

  return {
    integrations,
    providerOptions,
    qrCodeOptions,
    siteBuildingOptions,
    /** map of options available for each integration */
    virtualCredentialOptionsMap,
  };
}
